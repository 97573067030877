<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo d-none d-md-block">
        <LogoSAL />
      </b-link>

      <b-link class="brand-logo d-block d-md-none">
        <LogoSAL style="width: 50px; height: auto; position: absolute !important; top: 0 !important;" />
      </b-link>

      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5"
        >
          <b-img fluid :src="imgUrl" alt="Register V2" />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Register-->
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-card-title title-tag="h2" class="d-none d-md-block font-weight-bold mb-1 mt-2 mt-md-0">
            Adventure starts here 🚀
          </b-card-title>
          <b-card-title title-tag="h2" class="d-block d-md-none font-weight-bold  mb-1 mt-2 mt-md-0">
            <br/>
            <br/>
            Adventure starts here 🚀
          </b-card-title>
          <b-card-text class="mb-2">
            Sinergi, Taat, Aman & Responsif
            <hr />
            <b-badge variant="primary"> Pengguna Jasa </b-badge> ,
            <b-badge variant="primary"> BBM agents </b-badge> and
            <b-badge variant="primary"> Karyawan </b-badge>
          </b-card-text>

          <!-- form -->
          <validation-observer ref="registerForm">
            <b-form class="auth-register-form mt-2">
              <!-- username -->
              <b-form-group label="Perusahaan" label-for="register-perusahaan">
                <validation-provider
                  #default="{ errors }"
                  name="Perusahaan"
                  rules="required"
                >
                  <b-form-input
                    v-uppercase
                    id="register-perusahaan"
                    v-model="perusahaan"
                    name="register-perusahaan"
                    :state="errors.length > 0 ? false : null"
                    placeholder="PT Sarana Abadi Lestari"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group label="Nama Lengkap" label-for="register-full_name">
                <validation-provider
                  #default="{ errors }"
                  name="Nama Lengkap"
                  rules="required"
                >
                  <b-form-input
                    v-uppercase
                    id="register-full_name"
                    v-model="full_name"
                    name="register-full_name"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Nama Lengkap"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- wa -->
              <b-form-group
                label="Nomor Whatsapp * ( Ex: 8155 7777 33 )"
                label-for="register-phone"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Nomor Whatsapp"
                  rules="required"
                >
                  <b-input-group>
                    <b-input-group-prepend is-text>
                      ID (+62)
                    </b-input-group-prepend>
                    <cleave
                      id="phone"
                      v-model="phone"
                      class="form-control"
                      :raw="false"
                      :options="options.phone"
                      placeholder="8155 7777 33"
                      :state="errors.length > 0 ? false : null"
                    />
                  </b-input-group>

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- email -->
              <b-form-group label="Email" label-for="register-email">
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    id="register-email"
                    v-model="email"
                    name="register-email"
                    :state="errors.length > 0 ? false : null"
                    placeholder="star@sal.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- password -->
              <b-form-group label-for="register-password" label="Password">
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="register-password"
                      v-model="password"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      :state="errors.length > 0 ? false : null"
                      name="register-password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordToggleIcon"
                        class="cursor-pointer"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Privacy policy & terms"
                  rules="required"
                >
                  <b-form-checkbox
                    id="register-privacy-policy"
                    v-model="status"
                    name="checkbox-1"
                  >
                    I agree to
                    <b-link>privacy policy & terms</b-link>
                  </b-form-checkbox>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- Error's alert -->
          <b-alert v-if="error" variant="danger" show>
            <div class="alert-body font-small-2">
              <p v-text="error" />
            </div>
            <feather-icon
              icon="InfoIcon"
              size="18"
              class="position-absolute"
              style="top: 10; right: 10"
            />
          </b-alert>

              <b-button
                variant="primary"
                block
                type="submit"
                @click.prevent="validationForm"
                :disabled="loading"
              >
                <b-spinner v-if="loading" small />
                Sign up
              </b-button>
            </b-form>
          </validation-observer>

          <p class="text-center mt-2">
            <span>Already have an account?</span>
            <b-link :to="{ name: 'login' }">
              <span>&nbsp;Sign in instead</span>
            </b-link>
          </p>

          <!-- divider -->
          <div class="divider my-2">
            <div class="divider-text">or</div>
          </div>

          <div class="auth-footer-btn d-flex justify-content-center">
            <b-button
              @click="comingSoon()"
              variant="facebook"
              href="javascript:void(0)"
            >
              <feather-icon icon="FacebookIcon" />
            </b-button>
            <b-button
              @click="comingSoon()"
              variant="twitter"
              href="javascript:void(0)"
            >
              <feather-icon icon="TwitterIcon" />
            </b-button>
            <b-button
              @click="comingSoon()"
              variant="google"
              href="javascript:void(0)"
            >
              <feather-icon icon="MailIcon" />
            </b-button>
            <b-button
              @click="comingSoon()"
              variant="github"
              href="javascript:void(0)"
            >
              <feather-icon icon="GithubIcon" />
            </b-button>
          </div>
        </b-col>
      </b-col>
      <!-- /Register-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import Cleave from 'vue-cleave-component'
import 'cleave.js/dist/addons/cleave-phone.us'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BBadge,
  BRow,
  BCol,
  BLink,
  BButton,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BImg,
  BCardTitle,
  BCardText,
  BSpinner
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import LogoSAL from '@core/layouts/components/LogoSal.vue'
import useJwt from '@/auth/jwt/useJwt'
export default {
  components: {
    Cleave,
    BBadge,
    LogoSAL,
    BRow,
    BImg,
    BCol,
    BLink,
    BButton,
    BForm,
    BCardText,
    BCardTitle,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    // validations
    ValidationProvider,
    ValidationObserver,
    BSpinner
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      options: {
        phone: {
          // phone: true,
          // phoneRegionCode: 'ID',
          numericOnly: true,
          delimiters: [' ', ' ', ' '],
          blocks: [4, 4, 4]
        }
      },
      loading: false,
      error: '',
      status: '',
      perusahaan: '',
      full_name: '',
      email: '',
      password: '',
      sideImg: require('@/assets/images/pages/register-v2.svg'),
      // validation
      required,
      email
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/register-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    }
  },
  methods: {
    comingSoon() {
      this.$alert({
        title: 'Coming Soon',
        icon: 'HomeIcon',
        variant: 'success'
      })
    },
    validationForm() {
      this.$refs.registerForm.validate().then((success) => {
        if (success) {
          this.loading = true
          try {
            // Trim the phone number and remove any spaces
            let phoneNumber = this.phone.trim().replace(/\s+/g, '')

            if (phoneNumber.startsWith('0')) {
              // Remove the leading '0' if it exists
              phoneNumber = phoneNumber.slice(1)
            }

            // Now prepend the country code
            const formattedPhone = `62${phoneNumber}`
            useJwt
              .register({
                perusahaan: this.perusahaan,
                full_name: this.full_name,
                email: this.email,
                password: this.password,
                phone: formattedPhone
              })
              .then((response) => {
                this.loading = false
                const successData = {
                  full_name: this.full_name,
                  phone: this.phone
                }

                // Store the data in Vuex
                this.$store.commit('visitor/SET_SUCCESS_DATA', successData)

                this.$router.push({
                  path: '/register/success'
                })

              })
              .catch((error) => {
                try {
                  this.error = error.response.data.message
                } catch (err) {
                  this.error = error
                }
                this.loading = false
              })
          } catch (error) {
            this.loading = false
          }
        }
      })
    }
  }
}
/* eslint-disable global-require */
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
